<template>
    <div class="content">
        <div class="nav">
            <img :src="hostUrl+config.banner" class="img-auto" alt="">
        </div>
        <div class="search-box">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="身份证号" prop="idcard">
                    <el-input v-model="form.idcard" placeholder="请输入身份证号"></el-input>
                </el-form-item>
                <!-- <el-form-item label="区域情况" prop="region">
                    <el-select v-model="form.region" placeholder="请选择区域">
                    <el-option :label="item.title" :value="item.title" v-for="item in cateList" :key="item.value"></el-option>
                    </el-select>
                </el-form-item> -->
                
                <el-form-item>
                    <el-button type="primary" @click="onSearch('form')" style="width:100%;">查询</el-button>
                </el-form-item>
            </el-form>
            <el-dialog title="参赛证下载" :visible.sync="openPopImg" width="90%" append-to-body>
                <div>
                    <a ref="downloadLink" style="display: none;"></a>
                    <el-button style="margin-bottom: 20px;" type="primary" @click="download">点击立即下载</el-button>
                    <div id="cert">
                        <img :src="hostUrl+info.cert_img" alt="" class="img-auto" />
                    </div>
                </div>
            </el-dialog>

            <el-dialog title="参赛证下载" :visible.sync="openPop" width="600px" append-to-body>
                <div>
                    <el-button style="margin-bottom: 20px;" type="primary" @click="download">点击立即下载</el-button>
                    <div id="cert">
                        <div>
                            <div class="title" v-html="info.active_name"></div>
                            <table class="table">
                                <tr>
                                    <td style="width:2rem;"><b>姓名</b></td>
                                    <td colspan="2">{{info.name}}</td>
                                    <td rowspan="4">照片</td>
                                </tr>
                                <tr>
                                    <td><b>性别</b></td>
                                    <td colspan="2">{{info.sex}}</td>
                                </tr>
                                <tr>
                                    <td><b>证件号</b></td>
                                    <td colspan="2">{{info.idcard}}</td>
                                </tr>
                                <tr>
                                    <td><b>学校</b></td>
                                    <td colspan="2">{{info.school}}</td>
                                </tr>
                                <tr>
                                    <td><b>参赛项目</b></td>
                                    <td>{{info.project_name}}</td>
                                    <td><b>组别</b></td>
                                    <td>{{info.group_type}}</td>
                                </tr>
                                <tr>
                                    <td><b>参赛序号</b></td>
                                    <td>{{info.cert_number}}</td>
                                    <td><b>座位号</b></td>
                                    <td>{{info.site_number}}</td>
                                </tr>
                                <tr>
                                    <td><b>参赛地址</b></td>
                                    <td colspan="3">{{info.address}}</td>
                                </tr>
                                <tr>
                                    <td><b>参赛时间</b></td>
                                    <td colspan="3" class="txt">
                                        <p v-html="info.join_time"></p>
                                    </td>
                                </tr>
                            </table>
                            <div class="notice">
                                <b v-if="info.notice">提醒:<br /></b>
                                <p v-html="info.notice"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { Toast, Indicator } from 'mint-ui';
// import html2canvas from 'html2canvas';
// import { saveAs } from 'file-saver';
import axios from 'axios';
import config from "@/api/config";
import { getConfig,postSearch } from "@/api/chaxun/index";
export default {
    name: "Chaxun",
    data() {
        return {
            config:{},
            hostUrl:config.hostUrl,
            cateList:[],
            form:{
                name:'',
                idcard:'',
                region:''
            },
            info:{},
            openPop: false,
            openPopImg:false,
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' }
                ],
                idcard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' }
                ],
                // region: [
                //     { required: true, message: '请选择区域', trigger: 'change' }
                // ],
            },
            isWechat:false
        };
    },
    created(){
        this.loadConfig()
    },
    methods: {
        download(){
            this.isWechat = /MicroMessenger/i.test(navigator.userAgent);
            if(this.isWechat){
                return window.location.href = this.hostUrl+this.info.cert_img;
            }
            axios({
                url: this.hostUrl+this.info.cert_img,
                method: 'GET',
                responseType: 'blob' // 响应类型为 blob
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'image.png');
                document.body.appendChild(link);
                link.click();
                this.openPopImg = false;
                // Indicator.close();
                Toast('下载成功')
            });

            
            // Indicator.open({
            //     text: "正在下载...",
            //     spinnerType: "triple-bounce"
            // });
            
            // const element = document.getElementById('cert');
            // // 使用 html2canvas 将元素转换为 Canvas
            // const width = 600; // A4 纸的宽度，单位是像素
            // const height = width * 1.414; // A4 纸的高度，保持宽高比为 sqrt(2)
            // html2canvas(element,{
            //     scale: 2.8,
            //     width: width,
            //     // height: height
            // }).then(canvas => {
            //     // 将 Canvas 转换为图片的 Base64 URL
            //     // this.imageUrl = canvas.toDataURL();
            //     canvas.toBlob(blob => {
            //         console.log('===生成成功===')
            //         // Indicator.close();
            //         // Toast('生成成功')
            //         // 使用 file-saver 库保存 Blob 对象为文件
            //         saveAs(blob, 'downloaded-cert.png');
            //     });
            // });
        },
        loadConfig(){
            getConfig({}).then(res=>{
                this.config = res.data.config
                this.cateList = res.data.cate
            })
        },
        onSearch(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    postSearch(this.form).then(res=>{
                        if(res.status === 200){
                            this.info = res.data
                            // this.openPop = true;
                            this.openPopImg = true;
                            Toast('查询成功')
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped lang="less">
@font-face {
  font-family: 'MyCustomFont';
  src: url('~@/assets/FZQKBYSJW.ttf') format('truetype');
}
@font-face {
  font-family: 'HeadCustomFont';
  src: url('~@/assets/FANGZHENG.TTF') format('truetype');
}
.content{
    margin: 0 auto;
    .nav{
        width: 100%;
        img{
            width: 100%;
        }
    }
}
.search-box{
    display: flex;
    justify-content: center;
    width: 96%;
    margin: 120px auto;
}
#cert{
    border: 1px #ccc solid;
    padding: .4rem;
    font-family: 'MyCustomFont';
    text-align: center;
    color: #000;
    margin: 0 auto;
    .img-auto{
        max-width: 100%;
        height: auto;
    }
    .title{
        font-family: 'HeadCustomFont';
        font-size: 0.36rem;
        margin-bottom: .2rem;
        p{
            margin: 0;
            line-height: 1.5;
        }
    }
    .table {
        width: 100%;
        border: 1px #000 solid;
        border-collapse: collapse;
        text-align: center;
        font-size: .25rem;
        tr{
            height: .8rem;
        }
        thead {
          height: 40px;
          line-height: 24px;
        }
        th,
        td {
          border: .5px #000 solid;
          padding: 9px 0;
          font-weight: normal !important;
        }
        .txt{
            text-align: left;
            padding-left: .25rem;
            line-height: .54rem;
        }
    }
    .notice {
        margin-top: .4rem;
        text-align: left;
        padding-left: .15rem;
        font-size: .25rem;
        line-height: .54rem
    }
}


/deep/ .el-form-item label:after {
  content: "";
  width: 100%;
}
 /deep/ .el-form-item__label {
 /* display: inline-block必须要有，不然布局出问题，之前我看别人都加在伪类中，我试了没效果 */
  display: inline-block;
  text-align: justify;
  text-align-last: justify;
}
/* 这里去除必选字段的*,这个符号会造成一定影响,去掉之后我用了li列表进行定位,在前面加上" * ". */
 /deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}

</style>