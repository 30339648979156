import service from '@/utils/service'

export function getConfig(data){
    return service({
        url:'index/config',
        data
    })
}

export function postSearch(data){
    return service({
        url:'index/query',
        method:'POST',
        data
    })
}