import axios from "axios";
// import { Message,MessageBox } from "element-ui";
import { Toast, MessageBox } from 'mint-ui';
import store from '@/store'
// import { mapMutations } from 'vuex'
import config from '@/api/config';
import { getToken,removeToken } from "@/utils/auth";
const service = axios.create({
    // baseURL:config.baseUrl,//自动加载
    baseURL: '/api',
    timeout:10*1000//请求超时时间
})
//添加请求拦截器
service.interceptors.request.use((config)=>{
    //在请求之前需要的操作
    // Header--token
    // 判断追加token
    // let token = localStorage.getItem('token')
    config.headers['Authorization'] = getToken() ? getToken() : ''
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
          const value = config.params[propName];
          var part = encodeURIComponent(propName) + "=";
          if (value !== null && typeof(value) !== "undefined") {
            if (typeof value === 'object') {
              for (const key of Object.keys(value)) {
                let params = propName + '[' + key + ']';
                var subPart = encodeURIComponent(params) + "=";
                url += subPart + encodeURIComponent(value[key]) + "&";
              }
            } else {
              url += part + encodeURIComponent(value) + "&";
            }
          }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
      }

    return config
},(error)=>{
    return Promise.reject(error)
})


//添加响应拦截器
service.interceptors.response.use((response)=>{
    //对响应数据需要的操作
    let {data,meta} = response.data

    return Promise.resolve(response.data)
    // console.log(response,'#########')
    // console.log(data,'@@@@')
    // console.log(meta,'====meta===')
    if(meta.code == 401){
        removeToken()
        location.href = '/manage';
        return Promise.reject()
    }
    // if(response.status){
    //     console.log('errcode:',errcode)
        //提示重新登录
        // MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录?', '系统提示', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //     //删除登录缓存
        //     store.commit('user/deleteLoginToken')
        //     store.commit('user/deleteUser')
        //     //调整登录
        //     location.href = '/'; 
        //   }).catch(() => {});
        // return Promise.reject(response.data)
    // }
    if(meta.code != 200){
        // Message({message:msg || 'error',type:'warning'})
        MessageBox('提示', meta.msg);
        return Promise.reject(response.data)
    }
    // console.log(response.data,'============response.data==========')
    return Promise.resolve(response.data)
},(error)=>{
    console.log(error)
    let errors = error.response.data.errors
    return Promise.reject(errors)
})
export default service